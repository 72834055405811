// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-contact-js": () => import("./../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-m-power-js": () => import("./../src/pages/en/m-power.js" /* webpackChunkName: "component---src-pages-en-m-power-js" */),
  "component---src-pages-en-services-js": () => import("./../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-software-development-js": () => import("./../src/pages/en/software-development.js" /* webpackChunkName: "component---src-pages-en-software-development-js" */),
  "component---src-pages-en-thank-you-js": () => import("./../src/pages/en/thank-you.js" /* webpackChunkName: "component---src-pages-en-thank-you-js" */),
  "component---src-pages-fi-index-js": () => import("./../src/pages/fi/index.js" /* webpackChunkName: "component---src-pages-fi-index-js" */),
  "component---src-pages-fi-kiitos-js": () => import("./../src/pages/fi/kiitos.js" /* webpackChunkName: "component---src-pages-fi-kiitos-js" */),
  "component---src-pages-fi-m-power-js": () => import("./../src/pages/fi/m-power.js" /* webpackChunkName: "component---src-pages-fi-m-power-js" */),
  "component---src-pages-fi-ohjelmistokehitys-js": () => import("./../src/pages/fi/ohjelmistokehitys.js" /* webpackChunkName: "component---src-pages-fi-ohjelmistokehitys-js" */),
  "component---src-pages-fi-ota-yhteytta-js": () => import("./../src/pages/fi/ota-yhteytta.js" /* webpackChunkName: "component---src-pages-fi-ota-yhteytta-js" */),
  "component---src-pages-fi-palvelut-js": () => import("./../src/pages/fi/palvelut.js" /* webpackChunkName: "component---src-pages-fi-palvelut-js" */)
}

